import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { event } from 'vue-gtag';
import componentI18n from '@/i18n/componentI18n';
export default defineComponent({
    name: 'ResendEmailButton',
    ...componentI18n('components.resend_email_button'),
    data() {
        return { pressed: false };
    },
    computed: {
        ...mapGetters(['session'])
    },
    methods: {
        ...mapActions(['resendConfirmationEmail']),
        handleClick() {
            this.pressed = true;
            this.setTimer(this.content);
            this.resendConfirmationEmail(this.session.email);
            event('resend_confirmation_email', { event_category: 'engagement' });
        },
        setTimer() {
            const RESEND_TIMEOUT = 1500;
            setTimeout(() => {
                this.pressed = false;
            }, RESEND_TIMEOUT);
        }
    }
});
